<template>
  <div class="group">
      <div v-if="isVisible" @click="scrollToTop" class="transition fixed bottom-2 right-2 bg-transparent text-white p-2 rounded-full cursor-pointer z-50 h-[3rem] w-[3rem] flex place-content-center">
          <i class="transition pi pi-arrow-circle-up text-3xl group-hover:text-slate-100 text-gray-600/10 self-center font-black text-shadow group-hover:shadow-pink-500/50"></i>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            isVisible: false,
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        checkScroll() {
            this.isVisible = window.scrollY > 100;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.checkScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.checkScroll);
    },
};
</script>
