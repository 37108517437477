<template>
    <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="slide in slides" :key="slide">
            <div class="carousel__item">
                <img :src="slide.png" alt="slide" class="w-full h-full object-cover" />
            </div>
        </Slide>

        <template #addons>
            <Navigation />
        </template>
    </Carousel>
</template>

<script setup>
import { ref } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'


const props = defineProps({
    slides: {
        type: Array,
        required: true,
    },
    breakpoints: {
        type: Object,
        required: true,
    },
});

const settings = ref({
    itemsToShow: 1,
    snapAlign: 'center',
});

</script>
