
<template>

    <Head title="Welcome"/>

    <div class="landing-page">

        <GoToTopOfPageButton></GoToTopOfPageButton>

        <nav class="border-box py-6 px-4 w-full">
            <div class="container mx-auto container-max-width">
                <div class="flex justify-between items-center">
                    <div class="text-3xl font-black text-pink-600">
                        <img class="w-[10rem]" src="/img/landing/smart-sdr-logo-black.png" alt="">
                    </div>
                    <div class="hidden sm:block text-md font-medium space-x-4">
<!--                        <a href="#" class="text-gray-700 hover:text-gray-800">Home</a>-->
<!--                        <a href="#" class="text-gray-700 hover:text-gray-800">Learn</a>-->
                        <a href="#note-from-our-ceo" class="text-gray-700 hover:text-gray-800">Vision</a>
                        <a href="#testimonials" class="text-gray-700 hover:text-gray-800">Testimonials</a>
                    </div>
                    <div id="landing-button1"
                        class="hidden lg:block relative pulsating-button-animation landing-button-schedule-a-call"
                        type="button">
                        Schedule a call
                    </div>
                </div>
            </div>
        </nav>

        <div id="top-disclaimer" class="w-full h-[2rem] relative grid">
            <p class="place-self-center my-auto text-sm pulse-text text-center text-zinc-100 font-open-sans tracking-tight font-black ">"We've generated 15,675 sales meetings and counting!"</p>
        </div>

        <section id="top-section" class="landing-versioned-section top-section relative !py-1">
            <div id="top-section-background" class="piggy-pink-background"></div>

            <div class="top-section-container container-max-width">
                <div class="section-sub-container">
                    <div class="section-side section-h3-and-p w-full md:w-1/2 text-center md:text-left">
                        <div class="text-8xl md:text-7xl mb-3 font-bold font-fredoka tracking-tight text-gray-900 ">
                            <h1 class="">Spend less</h1>
                            <h1 class="text-emerald-500 text-shadow shadow-rose-400/80 ">sell more</h1>
                        </div>
                        <p class="  text-gray-700 mx-auto md:ml-0 mb-4">Book more
                            meetings and
                            close more deals on autopilot. </p>
                        <div id="landing-button2"
                            class="pulsating-button-animation relative landing-button-schedule-a-call m-auto md:ml-0 w-[10rem] block"
                            type="button">
                            Schedule a call
                        </div>
                    </div>
                    <div class="section-side w-full md:w-1/2">
                        <img class="drop-shadow-xl pt-6 px-6 md:pl-0 md:pr-auto md:w-[50rem] drop-shadow-[0_0_15px_theme('colors.amber[500]/30%')]"
                             src="/img/landing/piggybank-cropped.png" alt="">
                    </div>
                </div>

            </div>

            <div class="top-section-container container-max-width !mb-0 !pb-0 pt-[5rem]">
                <div class="first-section-robots-wrapper">
                    <div class="h-full relative-wrapper">
                        <img id="chatbot1" class="chatbot absolute" src="/img/landing/chatbot.png" alt="">
                        <img id="chatbot2" class="chatbot absolute" src="/img/landing/chatbot.png" alt="">
                    </div>
                </div>

                <div class="section-absolute-bottom-footer">
                    <div class="inline-block relative md:w-[40rem] grid mx-auto">
                        <p class="z-10 place-self-center self-center text-center text-gray-700 font-medium text-lg font-fredoka text-shadow shadow-rose-500/30">
                            Join 250+ B2B
                            companies driving sales opportunities with AI-powered
                            SDRs</p>
                        <TopSectionDownArrow class="hidden md:block landing-arrow arrow-gray-700 self-center absolute right-[-4.2rem] w-[5rem]"/>
                    </div>
                </div>
            </div>

        </section>

        <section id="companies-logos-carousel" class="!block">
            <SimplePngsCarousel id="companies-slider-carousel" :breakpoints="breakpointsForCompaniesSlider" :slides="companiesPngsForSlider" />
        </section>

        <section id="online-meeting-section" class="landing-versioned-section section-version1 bg-[rgb(250,243,228)] ">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div class="section-side w-full md:w-1/2">
                        <h3 class="w-full
                     font-black font-open-sans tracking-tight text-gray-900">Route discovery calls and demos directly to
                            your sales team.</h3>
                        <p class="  text-gray-700 mb-4">Our AI-Powered SDRs deliver
                            a steady flow of ideal customers right to your calendar.</p>
                    </div>
                    <div class="section-side w-full md:w-1/2">
                        <img class="w-[20rem] mx-auto pt-4 drop-shadow-[0_0_12px_theme('colors.amber[500]/30%')]" src="/img/landing/online-meeting-cropped.png" alt="">
                    </div>
                </div>
            </div>
        </section>

        <section id="irl-meeting-section" class="landing-versioned-section section-version1 bg-gray-800 overflow-hidden">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div
                        class="section-side w-full lg:w-[55%] order-last lg:order-none flex justify-center items-center">
                        <img class="translate-y-[2rem] md:px-20 lg:p-0 lg:scale-[120%] my-auto drop-shadow-[0_0_29px_theme('colors.slate[500]/50%')]" src="/img/landing/irl-meeting-cropped.png" alt="">
                    </div>
                    <div class="section-side w-full lg:w-[45%]">
                        <div class="align-middle">
                            <h3 class="w-full
                     font-black font-open-sans tracking-tight text-sky-300">We manage your entire campaign, end to
                                end.</h3>
                            <p class="
 text-stone-300"> Our team takes care of <span
                                class="before:bg-pink-500/50 text-pink-400 z-0">everything</span> – from
                                building your lead list and crafting messaging to initiating outreach and monitoring
                                your
                                omni-channel campaign every step of the way.</p>
                        </div>
                    </div>
                </div>
                <div class="absolute left-0 right-0 bottom-[-2rem] h-[2.5rem] overflow-hidden pb-3 w-full sm:w-[45%] ml-auto">
                    <div class="inline-block relative flex mx-auto">
                        <p class="z-10 place-self-start self-center text-center text-white font-medium text-lg font-permanentMarker inline">
                            AI Powered Sdrs</p>
                        <div class="arrow-extra-container place-self-stretch place-center w-[6rem] relative">
                            <AiPoweredSdrsDownArrow class="block landing-arrow arrow-white arrow-stroke-3 absolute w-full left-[1rem] top-[8px]"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="chatbots-section" class="landing-versioned-section section-version1 bg-slate-100 overflow-hidden ">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div class="section-side w-full md:w-1/2">
                        <h3 class="w-full
                     font-black font-open-sans tracking-tight text-gray-900">91% machine, 9% human.</h3>
                        <p class="text-gray-700 mb-4">Break free from limitations
                            and boundaries. Scale your outreach effortlessly by adding or removing SmartSDRs as
                            needed.</p>
                    </div>
                    <div class="section-side w-full md:w-1/2">
                        <div class="bots-and-guy-container h-[25rem] grid grid-rows-12">
                            <div class="four-bots-on-top row-span-3 grid grid-rows-1 grid-cols-4">
                                <img src="/img/landing/robot-with-laptop.png" alt=""
                                     class="bot-with-laptop max-w-full h-[100%] my-auto mx-auto">
                                <img src="/img/landing/robot-with-laptop.png" alt=""
                                     class="bot-with-laptop max-w-full h-[100%] my-auto mx-auto">
                                <img src="/img/landing/robot-with-laptop.png" alt=""
                                     class="bot-with-laptop max-w-full h-[100%] my-auto mx-auto">
                                <img src="/img/landing/robot-with-laptop.png" alt=""
                                     class="bot-with-laptop max-w-full h-[100%] my-auto mx-auto">
                            </div>

                            <img class="row-span-3 mt-2 place-self-center w-[80%]"
                                 src="/img/landing/arrows-robots-point.png" alt="">

                            <div class="guy-on-laptop row-span-6 flex">
                                <div class="relative human-monitor-image-and-arrow-container max-h-full mx-auto">
                                    <img class="h-full w-auto drop-shadow-[0_0_22px_theme('colors.amber[500]/20%')]" src="/img/landing/guy-in-desk.png" alt="">
                                    <div class="human-monitor-container absolute h-[70%] w-[90%] right-[-80%] bottom-0 grid">
                                        <HumanMonitorArrow class="landing-arrow arrow-stroke-2 h-[40%] place-self-center -translate-x-6 -translate-y-3"/>
                                        <span class="absolute bottom-0 right-0 human-monitor-text font-permanentMarker w-full !text-[1.3rem]">Human Monitor</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="bot-with-rocket-section" class="landing-versioned-section section-version1 bg-gray-800 overflow-hidden">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div class="section-side w-full md:w-[50%] order-last md:order-none flex justify-center items-center">
                        <img class="w-[20rem] drop-shadow-[0_0_22px_theme('colors.slate[500]/30%')]" src="/img/landing/rocket-with-robot.png" alt="">
                    </div>
                    <div class="section-side w-full md:w-[50%]">
                        <div class="align-middle h-full flex flex-col justify-center">
                            <h3 class="w-full
                     font-black font-open-sans tracking-tight text-sky-300">To infinity and beyond.</h3>
                            <p class="
 text-stone-300">Break free from limitations and boundaries. Scale your outreach effortlessly by adding or removing SmartSDRs as needed.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="teacher-section" class="landing-versioned-section section-version1 bg-slate-100 ">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div class="section-side w-full md:w-[55%]">
                        <h3 class="w-full
                     font-black font-open-sans tracking-tight text-gray-900">Zero training, <span class="text-emerald-500">zero turnover.</span></h3>
                        <p class="  text-gray-700 mb-4">Book more meetings and close more deals on autopilot.</p>
                    </div>
                    <div class="section-side w-full md:w-[45%]">
                        <img class="w-[27rem] md:scale-[110%] m-auto md:ml-0 drop-shadow-[0_0_22px_theme('colors.emerald[800]/40%')]" src="/img/landing/teacher-standing-in-class-alone.png" alt="">
                    </div>
                </div>
            </div>
        </section>

        <section id="engineer-bot-section" class="landing-versioned-section section-version1 bg-gray-800 overflow-hidden">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div
                        class="section-side w-full md:w-[50%] order-last md:order-none flex justify-center items-center">
                        <img class="w-[20rem] py-2 drop-shadow-[0_0_22px_theme('colors.slate[500]/40%')]" src="/img/landing/robot-engineer-cropped.png" alt="">
                    </div>
                    <div class="section-side w-full md:w-[50%]">
                        <div class="h-full pt-6 pb-5">
                            <h3 class="w-full
                     font-black font-open-sans tracking-tight text-sky-300">Continuous Improvement</h3>
                            <p class="
 text-stone-300">Built with adaptive algorithms, each SmartSDR learns from every interaction to improve its effectiveness over time.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="man-message-section" class="landing-versioned-section section-version1 bg-slate-100/30">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div class="section-side w-full md:w-[50%]">
                        <h3 class="w-full
                     font-black font-open-sans tracking-tight text-gray-900">One Voice, <span class="text-indigo-400 inline lg:inline">Many SDRs</span></h3>
                        <p class="  text-gray-700 mb-4">Book more meetings and close more deals on autopilot.</p>
                    </div>
                    <div class="section-side w-full md:w-[50%]">
                        <img class="pt-6 md:pt-0 w-[13rem] md:w-[15rem] mx-auto drop-shadow-[0_0_22px_theme('colors.indigo[500]/40%')]" src="/img/landing/man-message-cropped.png" alt="">
                    </div>
                </div>
            </div>
        </section>

        <section id="insights-graph-section" class="landing-versioned-section section-version1 bg-[rgb(250,243,228)] overflow-hidden">
            <div class="section-container container-max-width">
                <div class="section-sub-container">
                    <div
                        class="section-side w-full md:w-[50%] order-last md:order-none flex justify-center items-center">
                        <img class="w-[25rem] pt-12 md:pt-0 px-0 md:px-8 drop-shadow-[10px_-30px_22px_theme('colors.amber[700]/20%')]" src="/img/landing/3d-insights-cropped.png" alt="">
                    </div>
                    <div class="section-side w-full md:w-[50%]">
                        <div class="h-full">
                            <h3 class="w-full
                     font-black font-open-sans tracking-tight text-gray-800">Better Data, <span class="text-pink-500">Better Insights.</span></h3>
                            <p class="
 text-stone-700">Book more meetings and close more deals on autopilot</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="why-us-bottom-container" class="section-absolute-footer container-max-width">
                <div id="why-us-text-container" class="basis-1/3 grid">
                    <h3 class="font-permanentMarker text-[1.5rem] place-self-end self-center">Why us?</h3>
                </div>
                <div id="why-us-arrow-container" class="w-full basis-2/3 relative">
                    <WhyUsArrow class="landing-arrow absolute left-2 bottom-[0rem] absolute bottom-0 w-[20rem]" id="whyUsArrowSvg"></WhyUsArrow>
                </div>
            </div>
        </section>

        <section id="why-us-section" class="version1 dwarf-section">
            <div class="section-container">
                <div id="why-us-boxes-container">
                    <div class="why-us-box bg-red-500 text-slate-100 shadow-emerald-800/50">
                        <h3 class="on-top-title">Client Focused</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur corporis deleniti dignissimos dolores dolorum error eveniet id illo molestiae non nostrum quas quisquam quos rem rerum saepe tempora, unde voluptatibus.</p>
                    </div>
                    <div class="why-us-box bg-amber-400 shadow-blue-900/40">
                        <h3 class="on-top-title">Technology Driven</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aut, commodi dignissimos, doloremque explicabo facilis nihil nobis pariatur, perferendis quo quod repudiandae velit. Debitis dolore, esse nam nostrum odit voluptatibus.</p>
                    </div>
                    <div class="why-us-box bg-blue-500 shadow-yellow-800/70">
                        <h3 class="on-top-title">Data Backed</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate expedita nobis quas quos. Ab aut corporis, eius eos et facilis fugiat, laudantium numquam quis rem repellat repudiandae sed, ullam voluptate?</p>
                    </div>
                </div>
            </div>
        </section>

        <section id="note-from-our-ceo" class="landing-versioned-section section-version1 overflow-hidden !py-0">
            <div class="section-container !py-0 container-max-width !mt-0">
                <div class="section-sub-container !py-0">

                    <div id="note-from-our-ceo-block" class="">
                        <div id="jeremy-pic-container" class="">
                            <img class="drop-shadow-[0_0_52px_theme('colors.fuchsia[800]/15%')]" src="/img/landing/jeremy-our-ceo.png" alt="our-ceo">
                            <div id="ceo-text">
                                <h1 class="font-permanentMarker text-4xl pb-5 font-black text-shadow shadow-slate-500/70">NOTE FROM OUR CEO</h1>
                                <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias
                                    cumque dolores esse possimus rem! A aperiam consequatur dignissimos, doloremque
                                    doloribus dolorum, error hic, illo illum maxime molestias neque quas totam?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="testimonials" class="bg-gray-800">
            <div class="testimonial-cards-container">
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>“The team's knack for scheduling the right people is <span>remarkable</span> - they're absolutely <span>spot on!</span>  It's like printing money!”</p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>Chief Revenue Officer</h3>
                        <h6>3Data</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_3data-500.png" alt="3data">
                    </div>
                </div>
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>“The entire team is impressed with your <span>outstanding performance</span>
                            and results! We already have a significant deal in the works.”
                        </p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>Vice President of Sales</h3>
                        <h6>Ubiq Security</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_ubiq-security-500.png" alt="ubiq">
                    </div>
                </div>
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>“Today's meeting was one of the <span>best I've had this year.</span>
                            The team's ability to facilitate such <span
                            >effective outreach</span>
                            is incredible.”</p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>Chief Executive Officer</h3>
                        <h6>VnextLabs</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_vnextlabs-500.png"
                             alt="vnext">
                    </div>
                </div>
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>“Absolutely satisfied with the results! <span
                            class="">We’re having good conversations</span>
                            and have built a <span
                                class="">solid sales pipeline.</span> Great
                            work!”
                        </p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>Enterprise Sales Director</h3>
                        <h6>Rever</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_rever-500.png"
                             alt="rever">
                    </div>
                </div>
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>“We're seeing <span class="">tremendous value in the leads you're providing.</span>  We're planning to shift funds to increase our  investment. Excellent work!”
                        </p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>Vice-President of Technology and Marketing</h3>
                        <h6>Flex Fleet Rental</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_flex-fleet-rental-outline-500.png"
                             alt="flex-fleet-rental">
                    </div>
                </div>
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>“Our company has been working with airSales since 2019 and we couldn't be happier
                            with the service. Since our inception, airSales has produced a consistent flow of
                            qualified sales meetings that have led to new business. In regards to ROI, airSales
                            continues to be our #1 sales development channel for cold outreach!”</p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>President, PE Group</h3>
                        <h6>Valerity</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_valerity-500.png"
                             alt="valerity">
                    </div>
                </div>
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>“We've worked with airSales for a little over four months now, and it has been a great
                            experience. They are booking 2-3 high-quality potential clients a week for demos. This
                            has been just as effective, if not more, than a full-time sales staff member at
                            considerably less cost. If you are looking for a way to increase the amount of leads and
                            demos, give them a try and let it run for at least 3 months. You won't be disappointed!”</p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>Founder & CEO</h3>
                        <h6>Custom Donations</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_custom-donations-500.png"
                             alt="custom-donations">
                    </div>
                </div>
                <div class="testimonial-card">
                  <span class="testimonial-icon">
                    <img src="/img/landing/testimonial-quotes.png" alt="">
                  </span>
                    <div class="testimonial-text-container">
                        <p>
                            “Team AirSales were professional and responsive, and good about setting realistic
                            expectations for the ramp-up of meetings, as well as pro-active with optimization of
                            messaging and A/B testing. We would recommend airSales to others.”
                        </p>
                    </div>
                    <div class="testifier-title-container">
                        <h3>EVP Business and Corporate Development</h3>
                        <h6>Edjx</h6>
                    </div>
                    <div class="testimonial-footer">
                        <img class="testimonial-company-logo" src="/img/landing/logos/logo_edjx-500.png"
                             alt="edjx">
                    </div>
                </div>
            </div>
        </section>

        <section id="want-more-meetings" class="landing-versioned-section section-version1 dwarf-section">
<!--            <div class="piggy-pink-background"></div>-->
            <div class="section-container container-max-width !my-3">
                <div class="">
                    <div class="text-center">
                        <h3 class="w-full
                     font-black font-open-sans tracking-tight text-gray-900 mb-4 text-[3.75rem] leading-none">Want more
                            sales meetings?</h3>
                        <p class="m-auto max-w-[80%] text-[1.2rem] font-light leading-tight pb-5">Schedule a time to chat
                            with us. Our team is ready to help.</p>
                        <div id="land" class="landing-button-schedule-a-call pulsating-button-animation  relative w-[10rem] mx-auto">
                            Schedule a call
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="bg-gray-800 text-white p-8">
            <div class="max-w-screen-2xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-4">
                <div class="footer-col">
                    <h2 class="font-bold text-xl mb-2">SmartSDR.ai</h2>
                    <span class="underline"></span>
                    <p class="">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. At tempore, unde. A aliquam aspernatur cum deleniti doloremque eius ex hic, id ipsum labore optio quas quo, soluta suscipit voluptate. Odit!
                    </p>
                </div>
                <div class="footer-col">
                    <h2 class="font-bold text-xl mb-2">PRODUCTS</h2>
                    <span class="underline"></span>
                    <ul class="">
                        <li>MDBootstrap</li>
                        <li>MDWordPress</li>
                        <li>BrandFlow</li>
                        <li>Bootstrap</li>
                        <li>Angular</li>
                    </ul>
                </div>
                <div class="footer-col">
                    <h2 class="font-bold text-xl mb-2">USEFUL LINKS</h2>
                    <span class="underline"></span>
                    <ul class="">
                        <li>Your Account</li>
                        <li>Become an Affiliate</li>
                        <li>Shipping Rates</li>
                        <li>Help</li>
                    </ul>
                </div>
                <div class="footer-col">
                    <h2 class="font-bold text-xl mb-2">CONTACT</h2>
                    <span class="underline"></span>
                    <ul class="">
                        <li>New York, NY 10012, US</li>
                        <li>info@example.com</li>
                        <li>+01 234 567 88</li>
                        <li>+01 234 567 89</li>
                    </ul>
                </div>
            </div>
            <div class="text-center text-sm mt-4 absolute bg-gray-900 w-full h-10 right-0 flex items-center place-content-center">
                © {{year}} Copyright: SmartSDR.ai
            </div>
        </footer>


    </div>

</template>

<style lang="scss" scoped>

#top-disclaimer{

    @apply bg-gradient-to-r from-transparent from-10% via-teal-500/20 via-30% to-indigo-500/10 to-90%;
    // Base background goes in before
    &::before{
        @apply block absolute z-[-10] w-full h-full bg-emerald-500;
        content: "";
    }
}

.piggy-pink-background{
    @apply block absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center;
    background-image: url(/img/landing/piggy-background-1.png);
    background-size: cover;
    background-position: center;
}

.section-absolute-bottom-footer{
    @apply  absolute left-0 right-0 bottom-0 w-full h-auto  overflow-hidden;
}

section#note-from-our-ceo {
    #note-from-our-ceo-block {

        @apply w-full;

        #jeremy-pic-container {
            //clip-path: inset(0 0 0 0);
            //@apply relative;
            @apply h-full;
            img {
                @apply w-[30rem];
                @apply mx-auto;
                @media (min-width: theme('screens.md')) {
                    @apply w-[30rem] float-left;
                    float: left;
                    //max-width: 27rem;
                    height: auto;
                    shape-outside: url(/img/landing/jeremy-our-ceo.png);
                    shape-margin: 3rem;
                    filter: drop-shadow(0 0 2rem 2rem black);
                }

            }

            div#ceo-text {
                //@apply bg-gray-500/30;
                @apply h-auto w-full;
                @apply py-[3rem] px-[5rem];
                @media (min-width: theme('screens.md')) {
                    //@apply bg-emerald-500;
                    @apply w-full h-full pt-[5rem];
                    @apply py-[15%] px-0;
                }

                @media (min-width: theme('screens.lg')) {
                    //@apply bg-emerald-500;
                    @apply w-[85%] h-full pt-[5rem];
                    @apply py-[15%] px-0;
                }
            }
        }

    }
}

#why-us-boxes-container {
    @apply mx-auto;
    @apply py-5;
    @apply flex flex-col md:flex-row w-full lg:w-[65rem];
    .why-us-box {
        @apply md:basis-1/3;
        // size media queries
        //@apply sm:w-[18rem] sm:min-h-[20rem] sm:h-auto;
        //@apply md:w-[25rem] md:min-h-[20rem] md:h-auto;

        @apply p-5;

        @apply rounded-3xl shadow-lg m-2 flex flex-col;
        @apply text-white;
        h3 {
            @apply text-shadow;
            // title should be on top
            @apply text-2xl font-black block;
            @apply pb-3;
        }

        p {
            @apply text-shadow;
            // p should be below title
            @apply block;
        }
    }
}

$testimonial-primary-color: 'pink-500';

section#testimonials {

    .testimonial-cards-container {

        @apply max-w-screen-2xl mx-auto;
        // temporary until we figure the containers padding best use
        @apply p-10;
        @apply relative;

        //
        @apply grid grid-cols-4 gap-4;
        //@apply md:grid-cols-2 lg:grid-cols-4;
        .testimonial-card {
            @apply grid;
            @apply col-span-4 md:col-span-2 lg:col-span-1;
            grid-template-rows: fit-content(100%) auto fit-content(100%) fit-content(100%);
            //@apply h-[10rem] h-full;
            @apply bg-transparent;
            @apply border-2;
            @apply rounded-3xl;
            @apply shadow-lg;
            @apply min-h-[15rem] h-auto w-full;
            @apply mx-auto;
            @apply px-8 pt-10 pb-5;
            @apply text-white;
            @apply relative;

            span.testimonial-icon {
                @apply mb-4;
                @apply block;
                @apply w-[2.3rem] h-[2.3rem];
                @apply rounded-full;
                @apply bg-#{$testimonial-primary-color};

                img {
                    @apply p-2;
                }
            }

            .testimonial-text-container {
                @apply z-10;
                @apply h-full;
                @apply mb-auto;
                //@apply grid;
                //@apply self-center;
                p {
                    //@apply place-self-start;
                    @apply mb-3;
                    //@apply block;
                    @apply text-lg;
                    font-weight: 100;
                    @apply z-10;

                    span{
                        // TODO: supended until we find a no-line-breaking solution
                        //@apply bg-#{$testimonial-primary-color};
                        //@apply text-gray-900 font-medium text-shadow-sm shadow-white/50;
                        //@apply -skew-y-1;
                        //@apply inline-block;
                        //@apply before:box-decoration-clone;
                        //@apply before:inline-block before:absolute before:inset-[-1px] before:-z-10;
                        //@apply before:shadow-2xl before:shadow-white/30;
                        //@apply before:bg-#{$testimonial-primary-color};


                        @apply bg-#{$testimonial-primary-color};

                    }
                }
            }

            div.testifier-title-container {
                @apply mb-2;
                h3 {
                    //@apply bg-pink-500;
                    @apply text-xl;
                    @apply font-black;
                    @apply mr-auto;
                    @apply h-auto;
                    @apply mb-0;
                    @apply block;
                }
                h6 {
                    @apply hidden;
                }
            }

            div.testimonial-footer {
                @apply place-self-start self-end;
                @apply h-[2rem];
                //@apply bg-green-400;
                @apply w-full;
                //@apply grid grid-rows-2 place-content-start;
                img.testimonial-company-logo {
                    filter: contrast(0%) brightness(160%);
                    @apply place-self-end;
                    @apply max-h-[1.5rem];
                    @apply block;
                    @apply mt-0;
                    @apply mr-auto;
                    //@apply bg-amber-200/30;
                    //@apply mb-0;
                }
            }
        }
    }
}

.drop-background-pseudo-before {
    &::before {
        @apply block
        absolute
        -inset-0
        ml-auto
        w-full
        -skew-y-3
        inline-block
        -z-10
        overflow-visible
        blur-[18px]
    }
}

.company-logo{
    //@apply bg-emerald-500;
    @apply max-w-[8rem];
}


@mixin section-side {
    @apply relative text-center md:text-left;
}

@mixin section-h3-and-p {
    h3 {
        @apply mb-4 text-[2.75rem] leading-none;
    }

    p {
        @apply max-w-[87%] text-[1.7rem] font-light leading-tight mx-auto md:mx-0;
    }
}

.section-h3-and-p {
    @include section-h3-and-p;
}

section.landing-versioned-section {

    &.section-version1 {
        @apply border-b py-8;
        @apply min-h-[30rem];
        @apply relative;
        @apply flex;
    }

    &.top-section{
        .top-section-container {
            @apply overflow-visible;
            @apply relative;
            @apply sm:px-[3rem] mx-auto m-8;
        }
    }

    &.dwarf-section{
        @apply min-h-[10rem]
    }

    .section-container {
        //@apply bg-indigo-100;
        @apply overflow-visible;
        @apply relative;
        @apply my-auto mx-auto;
    }

    .section-sub-container{
        @apply flex flex-wrap px-2 xs:px-12 md:p-6;
    }

    .section-side {
        @include section-side;
        @include section-h3-and-p;
    }

    .section-absolute-footer {
        // Size limit and margin auto to mimic the section-container
        @apply my-auto mx-auto;
        @apply absolute flex flex-row bottom-0 w-full h-[3rem];

        // center bottom
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.container-max-width {
    @apply max-w-5xl;
}

footer{

    div.footer-col{
        @apply md:mx-auto;
        @apply py-8;
    }

    p{
        @apply max-w-[15rem];
    }

    p,li {
        @apply font-light;
    }


    .underline{
        @apply w-[40%] h-[2px] block mb-7;
    //    tailwind gradient from purple to indigo
        @apply bg-gradient-to-r from-purple-500 to-indigo-500;
    }

    ul {
        li {
            @apply my-3;
        }
    }
}

.bot-with-laptop {
    @apply drop-shadow-[0_0_22px_theme('colors.amber[900]/30%')];
}


.first-section-robots-wrapper {
    @apply absolute;
    @apply z-10;
    @apply right-0 bottom-[0rem] h-[10rem] w-[10rem];
    @apply h-[40rem]  md:w-[25rem] md:h-[25rem];

    .relative-wrapper {
        @apply relative;

        .chatbot {
            @apply drop-shadow-lg;
            @apply drop-shadow-[0_0_12px_theme('colors.gray[900]/40%')];
        }

        img#chatbot1 {
            @apply w-[8rem] left-0 bottom-[4rem];
            @apply sm:w-[8rem] sm:left-0 sm:bottom-[2rem];
            @apply md:w-[10rem] md:left-auto md:right-[4rem] md:bottom-[2rem];
            @apply lg:w-[12rem] lg:left-auto lg:right-[4rem] lg:bottom-[2rem];

        }

        img#chatbot2 {
            @apply w-[5rem] right-[0.1rem] bottom-[4rem];
            @apply sm:w-[5rem] sm:right-[0.1rem] sm:bottom-[2rem];
            @apply md:w-[6rem] md:right-3 md:bottom-[1rem];
            @apply lg:w-[7rem] lg:right-0 lg:bottom-[1rem];
        }
    }
}


.bg-dots-darker {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(0,0,0,0.07)'/%3E%3C/svg%3E");
}

@media (prefers-color-scheme: dark) {
    .dark\:bg-dots-lighter {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(255,255,255,0.07)'/%3E%3C/svg%3E");
    }
}

</style>

<script setup>
import {Head} from '@inertiajs/vue3';
import '../../css/app.css';

import WhyUsArrow from "../../../public/img/landing/arrow-why-us.svg";
import TopSectionDownArrow from "../../../public/img/landing/arrow-top-section.svg";
import AiPoweredSdrsDownArrow from "../../../public/img/landing/arrow-ai-powered-sdrs.svg";
import HumanMonitorArrow from "../../../public/img/landing/arrow-human-monitor.svg";

import GoToTopOfPageButton from "@/Components/GoToTopOfPageButton.vue";
import SimplePngsCarousel from "@/Components/SimplePngsCarousel.vue";
import {ref} from "vue";
import tailwind_vars from "@/tailwind_vars.js";

const logosBasePath = '/img/landing/logos/';
const companiesPngsForSlider = ref([
    {png: logosBasePath+'logo_uptycs.png'},
    {png: logosBasePath+'logo_rever-500.png'},
    {png: logosBasePath+'logo_ghrr.png'},
    {png: logosBasePath+'logo_sensehawk.png'},
    {png: logosBasePath+'logo_in-app-chat.png'},
    {png: logosBasePath+'logo_custom-donations-500.png'},
    {png: logosBasePath+'logo_edjx-500.png'},
    {png: logosBasePath+'logo_valerity-500.png'},
    {png: logosBasePath+'logo_vnextlabs-500.png'},
    {png: logosBasePath+'logo_flex-fleet-rental-500.png'},
]);

const pxToInteger = (px) => {
    return parseInt(px.replace('px',''));
}
const tailwind_breakpoints = tailwind_vars.screens;
let breakpointsForCompaniesSlider = ref({
    [pxToInteger(tailwind_breakpoints.xs)]: {
        itemsToShow: 3,
        snapAlign: 'start',
        autoplay: 7000,
        transition: 500,
        pauseAutoplayOnHover: true,
        // itemsToScroll: 1 ,
    },
    [pxToInteger(tailwind_breakpoints.md)]: {
        itemsToShow: 4,
        snapAlign: 'start',
        autoplay: 7000,
        transition: 500,
        pauseAutoplayOnHover: true,
        // itemsToScroll: 1 ,
    },
    [pxToInteger(tailwind_breakpoints.xl)]: {
        itemsToShow: 4,
        snapAlign: 'start',
        autoplay: 7000,
        transition: 500,
        pauseAutoplayOnHover: true,
        // itemsToScroll: 1 ,
    }
});

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
    laravelVersion: {
        type: String,
        required: true,
    },
    phpVersion: {
        type: String,
        required: true,
    },
});

const year = new Date().getFullYear();
</script>
